import React from "react";
import { useDispatch } from "react-redux";
import { startLogout } from "../../actions/auth";

export const Navbar = () => {
  //PUNTO NUMERO 7

  //7.1 Crear una funcion y hacer un respectivo dispatch

  //7.2 se manda a llamar el dispatch. auth.js

  const dispatch = useDispatch();

  const handleLogout = () => {
    //7.5 llamo la funcion de startLogout
    dispatch(startLogout());
  };

  //7.6 se llama la funcion en el boton

  //PUNTO 8 Crear la proteccion de rutas en el app router

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark barra">
      <a className="navbar-brand" href="#" style={{ marginLeft: "9%" }}>
        CW
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a className="nav-link" href="/">
              Principal <span className="sr-only">(current)</span>
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="/empresas">
              Empresas
            </a>
          </li> */}
        </ul>
        <form
          className="form-inline my-2 my-lg-0"
          style={{ marginRight: "10%" }}
        >
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button
            className="btn btn-outline-info my-2 my-sm-0"
            type="submit"
            onClick={handleLogout}
          >
            Logout
          </button>
        </form>
      </div>
    </nav>
  );
};
