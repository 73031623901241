import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { MainPage } from "../components/mainPage/MainPage";
import Empresas from "../components/empresas/Empresas";
import { AuthRouter } from "./AuthRouter";
import { firebase } from "../firebase/firebase-config";
import { useDispatch } from "react-redux";
import { login } from "../actions/auth";
import { Spinner } from "../components/spinner/Spinner";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const AppRouter = () => {
  //6.2 importar el useDispatch

  const dispatch = useDispatch();

  //6.3 Importar el useState
  const [checking, setChecking] = useState(true); //Me dice si estoy autenticado o no

  //6.5 Importar otro state para saber si se login o no

  const [isloggedIn, setIsloggedIn] = useState(false);

  //VAMOS PARA EL PUNTO 7 que es el navbar

  //6.1 Importar el use effect

  useEffect(() => {
    //Firebase me da la opcion de tener la autenticacion
    firebase.auth().onAuthStateChanged((user) => {
      if (user?.uid) {
        //Si existe signfica que estoy autenticado
        dispatch(login(user.uid, user.displayName));
        setIsloggedIn(true);
      } else {
        setIsloggedIn(false);
      }
      setChecking(false);
    });
  }, [dispatch, setChecking, setIsloggedIn]);

  //6.4 Hacer una condicion para mostrar
  if (checking) {
    return <Spinner />;
  }

  //PUNTO 8 IMPORTAR LAS  RUTAS PUBLICAS Y PRIVADAS

  //8.1 importar el private route en el componente de mainpage

  //PUNTO 9 INSERTAR LA ALERTA EN auth.js
  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            path="/auth"
            isAuthenticated={isloggedIn}
            component={AuthRouter}
          />
          <PrivateRoute
            exact
            isAuthenticated={isloggedIn}
            path="/"
            component={MainPage}
          />
          <PrivateRoute
            exact
            isAuthenticated={isloggedIn}
            path="/empresas"
            component={Empresas}
          />
          <Redirect to="/auth/login" />
        </Switch>
      </div>
    </Router>
  );
};
