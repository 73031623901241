import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  startGoogleLogin,
  startLoginEmailPassword,
} from "../../actions/auth";
import { Spinner } from "../spinner/Spinner";

export const LoginScreen = () => {
  //1.16 se llama useDispatch para poder enviar los datos ficticios
  const dispatch = useDispatch();

  //5.5 se implementa el useSelector
  const { loading } = useSelector((state) => state.ui); //necesito la propiedad de loading

  //1.10 se implementa el useForm y extraigo los valores que necesito

  const [formValues, handleInputChange] = useForm({
    email: "jorge.santamaria@celuweb.com",
    password: "123456",
  });

  //1.11 Extarer la informacion del formValues
  const { email, password } = formValues;

  //1.12 Implemento los valores del punto 1.11 en los campos del input

  //1.13 hacer el submit del formulario
  const handleLogin = (e) => {
    e.preventDefault();

    //1.14 hacer un dispatch del formulario al store. Se crean unas acciones en auth.js

    //1.17 hacer el dispacth al estado de redux
    //dispatch(login(12345, 'Juan david'))
    dispatch(startLoginEmailPassword(email, password));

    //1.18 se instala el thunk para usar en el store
  };

  //1.27 llamar la funcion de google
  const handleGoogleLogin = () => {
    dispatch(startGoogleLogin());
  };

  //PARTE NUMERO 2. ES CREAR EL USUARIO CON CORREO NORMAL

  return (
    <div className="contenedor-formulario">
      <form className="caja-login" onSubmit={handleLogin}>
        <div className="campo">
          <label>Email: </label>
          <input
            type="text"
            name="email"
            placeholder="Email"
            autoComplete="off"
            value={email}
            onChange={handleInputChange}
          />
        </div>

        <div className="campo">
          <label>Password: </label>
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={handleInputChange}
          />
        </div>

        <div className="campo enviar">
          <button type="submit" className="boton" disabled={loading}>
            Ingresar
          </button>
        </div>

        {/* <div className="campo enviar">
          <div className="auth__social-networks">
            <div className="google-btn">
              <div className="google-icon-wrapper">
                <img
                  className="google-icon"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                  alt="google button"
                />
              </div>
              <p className="btn-text" onClick={handleGoogleLogin}>
                <b>Sign in with google</b>
              </p>
            </div>
          </div>
        </div> */}

        {/* <div className="campo">
          <Link to="/auth/register">
            <p>Crea una cuenta nueva</p>
          </Link>
        </div> */}
      </form>
    </div>
  );
};
