import React, { useState, useEffect, useRef } from "react";

const DropDown = ({ options, selected, onSelectedChange, label }) => {
  const [open, setOpen] = useState(false);

  const renderedOptions = options.map((option) => {
    if (options && selected) {
      if (option.value === selected.value) {
        return null;
      }

      return (
        <div
          key={option.value}
          className="item"
          onClick={() => onSelectedChange(option)}
        >
          {option.label}
        </div>
      );
    }
  });

  return options && selected ? (
    <div className="ui form">
      <div className="field">
        <label className="label">{label}</label>
        <div
          onClick={() => setOpen(!open)}
          className={`ui selection dropdown ${open ? "visible active" : ""}` }
        >
          <i className="dropdown icon"></i>
          <div className="text">{selected.label}</div>
          <div className={`menu ${open ? "visible transition" : ""}`}>
            {renderedOptions}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default DropDown;
