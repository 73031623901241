// PASO NUMERO 1 DE LA APLICACION

import { types } from "../types/types";

//1.1 Se crea el auth reducer que contiene un estado y una accion

//EJEMPLO DE CARGAR DATOS EN EL ESTADO DE REDUX
 /* const initialState = {
    uid: 1234567890,
    name: "Juan David"
}  */


export const authReducer = (state={}, action) => { //Si escribo algo en le estado, me aparece en provide y auth
    //1.2 se crea una estructura switch para saber si esta logeado o no el usuario
    //1.3 se crean los types para saber si es login o logout
    switch (action.type) {
        case types.login:
            return{
                uid: action.payload.uid, //NOTA: Estos valores los devuelve Firebase
                name: action.payload.displayName
            }

            case types.logout:
            return{
                
            }
    
        default:
            return state;
    }
}
//1.4 se crea el store para que sea el corazon de la aplicacion