import React, { useState } from "react";
import DropDown from "../elementos/DropDown";
import TextBox from "../elementos/TextBox";
import { firebase } from "../../firebase/firebase-config";

const ModalAdd = () => {
  /*Se Inicializa el DropDown */
  const empresas = [
    {
      label: "Seleccione",
      value: "-1",
    },
    {
      label: "Alqueria",
      value: "alqueria",
    },
    {
      label: "Incusan",
      value: "incusan",
    },
  ];

  const tipos = [
    {
      label: "Seleccione",
      value: "-1",
    },
    {
      label: "Preventa",
      value: "preventa",
    },
    {
      label: "Autoventa",
      value: "autoventa",
    },
  ];

  const agregarAplicacion = () => {
    var id = 0;

    const data = firebase.database().ref("/" + selectedEmpresas.value + "/");
    data.on("value", (snapshot) => {
      const todos = snapshot.val();
      for (let empresa in todos) {
        id = empresa;
      }
    });
    const todoRef = firebase
      .database()
      .ref(selectedEmpresas.value + "/" + (parseInt(id) + 1) + "/");
    todoRef.update({
      estado: 0,
      inicio: inicio,
      sync: sync,
      empresa: selectedEmpresas.value,
      tipo: selectedTipos.value,
      imagen: imagen,
      id: parseInt(id) + 1,
    });
    window.$(".modal-observaciones").modal("hide");
    limpiarCampos();
  };

  const cerrarModal = () => {
    window.$(".modal-observaciones").modal("hide");
  };

  const limpiarCampos = () => {
    setSelectedEmpresas(empresas[0]);
    setSelectedTipos(tipos[0]);
    setSync("");
    setImagen("");
    setInicio("");
  };

  const [selectedEmpresas, setSelectedEmpresas] = useState(empresas[0]);
  const [selectedTipos, setSelectedTipos] = useState(tipos[0]);
  const [sync, setSync] = useState("");
  const [inicio, setInicio] = useState("");
  const [imagen, setImagen] = useState("");

  var prueba;

  return (
    <div className="modal modal-observaciones">
      <div className="container modal-container">
        <br></br>
        <h4>Agregar Aplicación:</h4>
        <table
          className="ui compact celled definition table"
          style={{ marginTop: "10px" }}
        >
          <tr>
            <td>
              <DropDown
                options={empresas}
                label="Empresa"
                selected={selectedEmpresas}
                onSelectedChange={setSelectedEmpresas}
              />
            </td>
          </tr>
          <tr>
            <td>
              <DropDown
                options={tipos}
                label="Tipo"
                selected={selectedTipos}
                onSelectedChange={setSelectedTipos}
              />
            </td>
          </tr>
          <tr>
            <td>
              <TextBox
                label="Inicio"
                valor={inicio}
                setValor={setInicio}
                placeholder="Inicio"
              ></TextBox>
            </td>
          </tr>
          <tr>
            <td>
              <TextBox
                label="Sync"
                valor={sync}
                setValor={setSync}
                placeholder="Sync"
              ></TextBox>
            </td>
          </tr>
          <tr>
            <td>
              <TextBox
                label="Imagen"
                valor={imagen}
                setValor={setImagen}
                placeholder="Url Imagen"
              ></TextBox>
            </td>
          </tr>
        </table>
        <div className="botonesRegistro">
          <button
            className="ui primary button"
            onClick={() => agregarAplicacion()}
          >
            Guardar
          </button>
          <button className="ui button" onClick={() => cerrarModal()}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAdd;
