import React, { useState, useEffect } from "react";
import { firebase } from "../../firebase/firebase-config";
import { Navbar } from "../navbar/Navbar";
import circulo_rojo from "../../styles/img/circulo_rojo.png";
import circulo_verde from "../../styles/img/circulo_verde.png";
import ModalAdd from "../modals/ModalAdd";
import ModalEdit from "../modals/ModalEdit";

export const MainPage = (props) => {
  const editar = (empresa, id, estadoactual) => {
    const todoRef = firebase.database().ref(empresa + "/" + id + "/");
    todoRef.update({ estado: estadoactual === 0 ? 1 : 0 });
  };

  const [listaEmpresas, setListaEmpresas] = useState();
  const [empresaEditar, setEmpresaEditar] = useState();

  const desplegarModal = () => {
    window.$(".modal-observaciones").modal("show");
    // document
    // .querySelector(".modal-observaciones")
    // .classList.toggle("show-modal");
  };

  const editarEmpresa = (todo) => {
    setEmpresaEditar(todo);
    window.$(".modal-ediciones").modal("show");
  };

  useEffect(() => {
    const empresas = firebase.database().ref("/");
    empresas.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let empresa in todos) {
        for (let id in todos[empresa]) {
          todoList.push({
            tipo: todos[empresa][id].tipo,
            imagen: todos[empresa][id].imagen,
            inicio: todos[empresa][id].inicio,
            sync: todos[empresa][id].sync,
            estado: todos[empresa][id].estado,
            empresa: todos[empresa][id].empresa,
            id: todos[empresa][id].id,
          });
        }
      }
      setListaEmpresas(todoList);
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container" style={{ marginTop: "2%" }}>
        <div>
          <table className="ui compact celled definition table mt-5 type">
            <thead className="text-center">
              <tr>
                <th>Desactivar/Activar</th>
                <th>Logo</th>
                <th>Empresa</th>
                <th>Tipo</th>
                <th>Inicio</th>
                <th>Sync</th>
                <th>Estado</th>
                <th>Administrar</th>
              </tr>
            </thead>
            <tbody>
              {listaEmpresas
                ? listaEmpresas.map((todo, index) => (
                    <tr>
                      <td className="collapsing">
                        <div className="ui fitted slider checkbox">
                          <input
                            type="checkbox"
                            checked={todo.estado === 0 ? false : true}
                            onChange={() =>
                              editar(todo.empresa, todo.id, todo.estado)
                            }
                          />
                          <label></label>
                        </div>
                      </td>
                      <td>
                        <img
                          className="ui Tiny circular image imagenLogo"
                          src={todo.imagen}
                        />
                      </td>
                      <td className="celdasEditadas type text-center">
                        {todo.empresa}
                      </td>
                      <td className="celdasEditadas type text-center">
                        {todo.tipo}
                      </td>
                      <td className="celdasEditadas type text-center">
                        {todo.inicio}
                      </td>
                      <td className="celdasEditadas type text-center">
                        {todo.sync}
                      </td>
                      <td>
                        <img
                          className="ui Tiny circular image imagenEstado"
                          src={todo.estado === 0 ? circulo_rojo : circulo_verde}
                        />
                      </td>
                      <td className="celdasEditadas type text-center">
                        <div
                          className="ui vertical animated button"
                          tabindex="0"
                          onClick={() => editarEmpresa(todo)}
                        >
                          <div className="hidden content">Edit</div>
                          <div className="visible content">
                            <i className="edit icon"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
            <tfoot className="full-width">
              <tr>
                <th></th>
                <th colspan="7">
                  <div
                    className="ui right floated small primary labeled icon button"
                    onClick={() => desplegarModal()}
                  >
                    <i className="plus icon"></i> Agregar
                  </div>
                  {/* <div className="ui small button">Approve</div>
                  <div className="ui small  disabled button">Approve All</div> */}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <ModalAdd />
      <ModalEdit empresaEditar={empresaEditar} />
    </div>
  );
};
