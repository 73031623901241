import { types } from "../types/types";


//2.7 definir los types en el archivo en los types.js

const initialState = {
    loading: false,
    msgError: null
}
export const uiReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.uiSetError:
            return{
               ...state,
               msgError: action.payload 
            }

            case types.uiremoveError:
            return{
               ...state,
               msgError: null 
            }

            //5.2 poner las acciones

            case types.uiStartLoading: 
            return {
                ...state,
                loading: true
            }

            case types.uiFinishLoading: 
            return {
                ...state,
                loading: false
            }
    
        default:
            return state;
    }
}

//2.9 llamar el archivo en le store

