import { Provider } from "react-redux";
import { store } from "./store/store";

import { AppRouter } from "./routers/AppRouter";

function App() {
  return (
    //1.8 se llama el store para que cargue en la herramienta de los desarrolladores, pero se debe importar el provider antes
   <div>
     <Provider store={store}>
        <AppRouter/>
     </Provider>
   </div>
  );
}

export default App;

//1.9 se implementa el redux en el store y vamos a loginScreen para implementar el useForm
