//1,23 importar firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
  // apiKey: "AIzaSyAuUefHMy7EZ30fJvAh69Neu7JWEdZ4w2o",
  // authDomain: "base-project-280c8.firebaseapp.com",
  // databaseURL: "https://base-project-280c8.firebaseio.com",
  // projectId: "base-project-280c8",
  // storageBucket: "base-project-280c8.appspot.com",
  // messagingSenderId: "1042371615141",
  // appId: "1:1042371615141:web:c07585cebf130cc94a304c"
  apiKey: "AIzaSyCrIYe_30qkSFgRleQlu5BhKVOYMQ7lbk8",
  authDomain: "contingenciacw-default-rtdb.firebaseapp.com",
  databaseURL: "https://contingenciacw-default-rtdb.firebaseio.com/",
  projectId: "contingenciacw",
  storageBucket: "contingenciacw.appspot.com",
  messagingSenderId: "496901713606",
  appId: "1:496901713606:android:2c10a6d3fedf6bef1c31c7",
  measurementId: "G-X9EV3TW0YM",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore(); //base de datos
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { db, googleAuthProvider, firebase };
