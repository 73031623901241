import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import DropDown from "../elementos/DropDown";
import TextBox from "../elementos/TextBox";
import { firebase } from "../../firebase/firebase-config";

const ModalEdit = ({ empresaEditar }) => {
  
  const [selectedEmpresas, setSelectedEmpresas] = useState("-1");
  const [selectedTipos, setSelectedTipos] = useState("-1");
  const [sync, setSync] = useState("");
  const [inicio, setInicio] = useState("");
  const [imagen, setImagen] = useState("");
  useEffect(() => {
    if (empresaEditar) {
      establecerValores();
    }
  }, [empresaEditar]);

  const empresas = [
    {
      label: "Seleccione",
      value: "-1",
    },
    {
      label: "Alqueria",
      value: "alqueria",
    },
    {
      label: "Incusan",
      value: "incusan",
    },
  ];

  const tipos = [
    {
      label: "Seleccione",
      value: "-1",
    },
    {
      label: "Preventa",
      value: "preventa",
    },
    {
      label: "Autoventa",
      value: "autoventa",
    },
  ];

  const establecerValores = () => {
    var tipoSelected = tipos.filter(
      (tipo) => tipo.value === empresaEditar.tipo
    )[0];

    var empresaSelected = empresas.filter(
      (empresa) => empresa.value === empresaEditar.empresa
    )[0];
    setSelectedEmpresas(empresaSelected);
    setSelectedTipos(tipoSelected);
    setInicio(empresaEditar.inicio);
    setSync(empresaEditar.sync);
    setImagen(empresaEditar.imagen);
    window.$(".modal-ediciones").modal("show");
  };

  const editarAplicacion = () => {
    var id = 0;
    const data = firebase.database().ref("/" + empresaEditar.empresa + "/");
    data.on("value", (snapshot) => {
      const todos = snapshot.val();
    });
    const todoRef = firebase
      .database()
      .ref(empresaEditar.empresa + "/" + empresaEditar.id + "/");
    todoRef.update({
      estado: 0,
      inicio: inicio,
      sync: sync,
      tipo: selectedTipos.value,
      imagen: imagen,
    });
    window.$(".modal-ediciones").modal("hide");
  };

  const eliminarAplicacion = () => {
    const data = firebase.database().ref("/" + empresaEditar.empresa + "/");
    data.on("value", (snapshot) => {
      const todos = snapshot.val();
    });
    const todoRef = firebase
      .database()
      .ref(empresaEditar.empresa + "/" + empresaEditar.id + "/");
    todoRef.remove();
    window.$(".modal-ediciones").modal("hide");
  };

  const cerrarModal = () => {
    window.$(".modal-ediciones").modal("hide");
  };

  return empresaEditar ? (
    <React.Fragment>
      <div className="modal modal-ediciones">
        <div className="container modal-container">
          <br></br>
          <h4 style={{ fontWeight: "bold" }}>
            Administrar Aplicación: <br></br>
            <br></br> {empresaEditar.empresa} {empresaEditar.tipo}
            <br></br>
            <br></br>
          </h4>
          <table
            className="ui compact celled definition table"
            style={{ marginTop: "10px" }}
          >
            {/* <tr>
              <td>
                <DropDown
                  options={empresas}
                  label="Empresa"
                  selected={selectedEmpresas}
                  onSelectedChange={setSelectedEmpresas}
                />
              </td>
            </tr> */}
            <tr>
              <td>
                <DropDown
                  options={tipos}
                  label="Tipo"
                  selected={selectedTipos}
                  onSelectedChange={setSelectedTipos}
                />
              </td>
            </tr>
            <tr>
              <td>
                <TextBox
                  label="Inicio"
                  valor={inicio}
                  setValor={setInicio}
                  placeholder="Inicio"
                ></TextBox>
              </td>
            </tr>
            <tr>
              <td>
                <TextBox
                  label="Sync"
                  valor={sync}
                  setValor={setSync}
                  placeholder="Sync"
                ></TextBox>
              </td>
            </tr>
            <tr>
              <td>
                <TextBox
                  label="Imagen"
                  valor={imagen}
                  setValor={setImagen}
                  placeholder="Url Imagen"
                ></TextBox>
              </td>
            </tr>
          </table>
          <div className="botonesRegistro">
            <button
              className="ui primary button"
              onClick={() => editarAplicacion()}
            >
              Editar
            </button>
            <button
              className="ui red button"
              onClick={() => eliminarAplicacion()}
            >
              Eliminar
            </button>
            <button className="ui button" onClick={() => cerrarModal()}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export default ModalEdit;
