import React from 'react'
import { Link } from 'react-router-dom'
import validator from 'validator'
import { useForm } from '../../hooks/useForm'
import {useDispatch, useSelector} from 'react-redux'
import { removeError, setError } from '../../actions/ui'
import { startRegisterWithEmailPasswordName } from '../../actions/auth'
import Swal from 'sweetalert2'

export const RegisterScreen = () => {

  //2.14 //Importar el dispatch

  const dispatch = useDispatch()

  //2.16 importar el useSelector
  
  const {msgError} = useSelector(state => state.ui); //regresa el estado de redux

  //PASO NUMERO 2 DE LA APLICACION TAREA

   //2.1 Se importa el useForm para agregar los valores
   const [formValues, handleInputChange] = useForm({
     name: "Juan David Vargas",
     email: "rexraptor121@outlook.com",
     password: 'jda9rx',
     password2: 'jda9rx'
   })

   //2.2 desestructurar el formValues
   const {name,email,password,password2} = formValues;

   //2.3 Enviar los valores a los inputs

   //2.4 hacer la simulacion de enviar datos

   const handleRegister = (e)=>{
    e.preventDefault();
    if(isFormValid()){ //Se dispara la funcion si tiene un true
      //console.log('formulario correcto'); 

      //3.1 LLAMAR LA FUNCION PARA HACER EL REGISTRO
       dispatch(startRegisterWithEmailPasswordName(email, password, name))
    }
   }

   //2.5 Hacer la validacion del formulario
   const isFormValid = ()=>{
     if(name.trim().length === 0){

       //2.15 enviar los errores por dispatch
       dispatch(setError('name is requiered'));
       //console.log('name is requiered');
       return false;

     } else if(!validator.isEmail(email)){ //Instalar el validator
      dispatch(setError('Email is not valid'));
       //console.log('Email is not valid');
       return false;
     } else if(password !== password2 || password.length < 5){
       dispatch(setError('Password should be at least six characters and match each other'));
       //console.log('Password should be at least six characters and match each other')
       return false
     }
     dispatch(removeError);
     return true
   }

   //2.6 crear el ui para manejar los errores en pantalla y no por consola. Se crea en los reducers

    return (
        <div className="contenedor-formulario">
        <h1>
          UpTask <span>Crear Cuenta</span>
        </h1>
        <form className=" caja-login" onSubmit={handleRegister}>


        {/* 2.6 Enviar la notificación */}

        { //2.17 se llama la notificacion con el error que presente y saltamos al punto 3 en auth.js
          msgError &&
          (
        <div className="alert alert-danger text-center" role="alert">
             {msgError}
       </div> 

          )
        }



          <div className="campo">
            <label>Nombre: </label>
            <input
              type="text"
              name="name"
              placeholder="nombre"
              autoComplete="off"
              value={name}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="campo">
            <label>Email: </label>
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="campo">
            <label>Password: </label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="campo">
            <label>Repita el Password: </label>
            <input
              type="password"
              name="password2"
              placeholder="Repetir el Password"
              value={password2}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="campo enviar">
            <button type="submit" className="boton">
              Crear cuenta
            </button>
          </div>
          <div className="campo">
            <Link to="/auth/login">
              <p>Iniciar Sesión</p>
            </Link>
          </div>
        </form>
      </div>
    )
}
