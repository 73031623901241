//1.15 crear la accion que se llama login 
import { types } from "../types/types"
import {firebase, googleAuthProvider} from '../firebase/firebase-config';
import {uiStartLoading, uiFinishLoading} from './ui';
import Swal from 'sweetalert2';



//1.21 Crear la acion del boton de gmail
//1.22 crear una carpeta llamada firebase
export const startLoginEmailPassword = (email, password)=>{
//regresa un callback
return (dispatch) => {

  //5.4 llamar las acciones de starLoading y dispatch(finishLoading);
   dispatch(uiStartLoading())

    //PUNTO 4 LOGEARSE CON EL CORREO NORMAL.

    //4.1 usar la autenticacion de firebase para logearse
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then(({user})=>{ 
      dispatch(login(user.uid, user.displayName));
      dispatch(uiFinishLoading());
      //console.log(user);
    })
    .catch(e =>{
      dispatch(uiFinishLoading()); //DESPUES SE PASA A LoginScreen
      console.log(e);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.message
      })
      
    })
  }
}

//PUNTO 5 TAREA DE DESHABILITAR EL BOTON. ir a types.js
//PUNTO 6 DESPUES VAMOS AL APP ROUTER PARA CONFIGURAR LA AUTENTICACIONs

//PUNTO 3 CREAR EL REGISTRO CON CORREO NORMAL Y DESPUES AL LOGIN SCREEN

export const startRegisterWithEmailPasswordName = (email, password, name)=>{
  return (dispatch) => {
   firebase.auth().createUserWithEmailAndPassword(email, password)
   .then( async({user})=>{ 
    //3.2 Obtener el displayname
    await user.updateProfile({displayName: name});

    dispatch(login(user.uid, user.displayName))
  })
  .catch(e =>{
    console.log(e);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: e.message
    })
  })
}
}


//1.23 crear la funcionalidad del boton de google
//1.24 llamar la accion en el boton de google
export const startGoogleLogin = () =>{
    return (dispatch)=>{
     //1.25 se importa la configuracion de firebase
     firebase.auth().signInWithPopup(googleAuthProvider)
     .then(({user})=>{ //1.26 desestructurar la info que envia gmail y enviar por dispatch
        dispatch(
          login(user.uid, user.displayName)
        )
      })
    }
}


//7.3 crear la funcion del logout
export const startLogout = ()=>{
  return async(dispatch)=>{
   await firebase.auth().signOut();
   dispatch(logout());
  }
}

//7.4 Crear una funcion pequeña para que el logout funciones
export const logout = ()=>({
  type: types.logout
})



//1.16 
export const login = (uid, displayName) =>({ //Esta accion se llama cuando tenga el uid y el displayName
         type: types.login,
         payload:{
             uid,
             displayName //Va a regresar un objeto con estas especifiaciones
         }
    })

