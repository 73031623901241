import { authReducer } from "../reducers/authReducer";
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import { uiReducer } from "../reducers/uiReducer";

//1.19 Importar el middleware
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;


//1.5 se crea el combineReducers para incorporar varios reducers
const reducers = combineReducers({
    //NOTA: El nombre auth || authentication es el que se muestra en la pantala de redux
    auth: authReducer,
    ui: uiReducer      // 2.10 Implementar los errores en el reducer y se crean acciones para el ui
})

//1.6 se crea el store
export const store = createStore(
    reducers,
    //1.10 se importa el redux para que funcione en la consola
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 


    //1.20 llamar el composeEnhancer e ir al auth a crear la accion de gmail
    composeEnhancers(
        applyMiddleware(thunk)
    )
    );


//1.7 Se importa el store en lo mas alto de la aplicacion y este es el componente App