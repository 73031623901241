import React, { useState, useEffect, useRef } from "react";

const TextBox = ({ placeholder, label, valor, setValor }) => {
  const [open, setOpen] = useState(false);

  const cambiarTexto = (value) => {
    setValor(value);
  };

  // useEffect(() => {
  //   const onBodyClick = (event) => {
  //     if (ref.current.contains(event.target)) {
  //       return;
  //     }
  //     setOpen(false);
  //   };

  //   document.body.addEventListener("click", onBodyClick);

  //   return () => {
  //     document.body.removeEventListener("click", onBodyClick);
  //   };
  // }, []);

  //   const renderedOptions = options.map((option) => {
  //     if (option.value === selected.value) {
  //       return null;
  //     }

  //     return (
  //       <div
  //         key={option.value}
  //         className="item"
  //         onClick={() => onSelectedChange(option)}
  //       >
  //         {option.label}
  //       </div>
  //     );
  //   });

  return (
    <div className="ui form">
      <div className="field">
        <label className="label">{label}</label>
        <div class="ui input">
          <input
            type="text"
            placeholder={placeholder}
            value={valor}
            onChange={(e) => cambiarTexto(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default TextBox;
