export const types = {
    login: '[Auth] Login',
    logout: '[Auth] Logout',

    //ERRORES
    //2.8 crear los types del error
    uiSetError: '[UI] set Error',
    removeError: '[UI] remove Error',

    //5.1 Crear dos tipos en nuestro types.js y despues en el ui
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading'
}
