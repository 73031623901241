//2.11 crear el archivo y exportarlo

import { types } from "../types/types";


export const setError = (err)=>({
    //2.12 se llaman los types
    type:types.uiSetError,
    payload: err
})


export const removeError = ()=>({
    type:types.removeError
})

//2.13 Importar el ui en el register para validar los errores



// 5.3 definir las acciones y despues ponerlos en el auth.js (startLoginEmailPassword)
export const uiFinishLoading =()=>({
    type: types.uiFinishLoading
})

export const uiStartLoading =()=>({
    type: types.uiStartLoading
})